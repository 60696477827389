<template>
  <span shrink-0 aspect="1/1" sm:h-8 xl:h-10 class="rtl-flip"><svg
    xmlns="http://www.w3.org/2000/svg" w-full
    aspect="1/1" sm:h-8 xl:h-10 sm:w-8 xl:w-10 viewBox="0 0 250 250" fill="none"
  >
    <mask id="a" width="240" height="234" x="4" y="1" maskUnits="userSpaceOnUse" style="mask-type:alpha">
      <path
        fill="#D9D9D9"
        d="M244 123c0 64.617-38.383 112-103 112-64.617 0-103-30.883-103-95.5C38 111.194-8.729 36.236 8 16 29.46-9.959 88.689 6 125 6c64.617 0 119 52.383 119 117Z"
      />
    </mask>
    <g mask="url(#a)">
      <path
        class="body"
        d="M116.94 88.1c-13.344 1.552-20.436-2.019-24.706 10.71 0 0 14.336 21.655 52.54 21.112-2.135 8.848-1.144 15.368-1.144 23.207 0 26.079-20.589 48.821-65.961 48.821-23.03 0-51.015 4.191-72.367 15.911-15.175 8.305-27.048 20.336-32.302 37.023l5.956 8.461 11.4.155v47.889l-13.91 21.966L-19.556 387h13.192l1.144-51.227c6.558-3.881 21.58-13.971 34.391-29.494 17.386-20.879 30.731-51.227 15.022-88.793l11.744-5.045c12.887 30.814 8.388 57.514-2.898 79.013 21.58-.698 40.11-2.095 55.819-4.734l-3.584-43.698 12.659-1.087L129.98 387h13.116l2.212-94.459c10.447-4.502 34.239-21.034 45.372-78.47 1.372-6.986 2.135-12.885 2.516-17.93 1.754-12.806 2.745-27.243 3.051-43.698l-18.683-5.976h57.42l5.567-12.807c-5.414.233-11.896-2.639-11.896-2.639l1.297-6.209H242l-65.199-34.384c-7.244 2.794-14.87 6.442-20.208 10.866-4.27-3.105-19.063-12.807-39.653-13.195Z"
      />
      <path
        class="wood"
        d="M6.217 24.493 18.494 21c5.948 21.577 13.345 33.375 22.648 39.352 8.388 5.099 19.75 5.239 31.799 4.579-3.508-1.164-6.787-2.794-9.837-5.045-6.787-5.045-12.582-13.428-16.929-28.64l12.201-3.649c3.279 11.488 7.092 18.085 12.201 21.888 5.11 3.726 11.286 4.657 18.606 5.433 13.726 1.553 30.884 2.174 52.312 12.264 2.898 1.086 5.872 2.483 8.769 4.036-.381-.776-.762-1.553-1.296-2.406-3.66-5.822-10.828-11.953-24.097-16.92l4.27-12.109c21.581 7.917 30.121 19.171 33.553 28.097 3.965 10.168 1.525 18.124 1.525 18.124-3.05 1.009-6.1 2.406-9.608 3.492-6.634-4.579-12.887-8.033-18.835-10.75-21.962-8.304-43.466-2.638-62.53-.853-14.336 1.32-27.452.698-38.814-6.598-11.21-7.14-21.047-20.8-28.215-46.802Z"
      />
      <path
        class="wood"
        d="M90.098 45.294c-2.516-5.744-4.041-12.807-3.355-21.5l12.659.932c-.763 10.555 2.897 17.696 7.015 22.353-5.338-.931-10.447-1.04-16.319-1.785ZM170.167 43.974l8.312-9.702c21.58 19.094 8.159 46.415 8.159 46.415l-11.819-1.32c-.382-6.24-1.144-17.836-6.635-24.371 3.584 1.84 6.635 3.865 9.99 6.908 0-5.666-1.754-12.341-8.007-17.93Z"
      />
    </g>
  </svg>
  </span>
</template>

<style scoped>
svg path.wood {
    fill: var(--c-primary);
}
svg path.body {
    fill: var(--c-text-secondary);
}
</style>
